import React from "react"
import styles from "../components/modal/modal.module.css"
import CloseIcon from "@material-ui/icons/Clear"
import ReactPannellum, {getConfig,addScene} from "react-pannellum"
import leftArrow from "../images/leftArrow.svg"
import rightArrow from "../images/rightArrow.svg"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowTopIosIcon from "@material-ui/icons/ArrowForwardIos";
// import ArrowDownIosIcon from "@material-ui/icons/ArrowForwardIos";
import rotateScreen from '../images/rotateMobile.png';
import { Link } from "gatsby";
import VRGlass from "../images/VR_glass.svg"

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPreview: false,
      thumbnailPano:false,
      panoClickedSrc:"",
      panoClickedID:"",
      panoClickedHotspot:[],
      panoClickedTitle:"",
      timePassed: false,
      toggle:false,
      chooseImage:null
    }
    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();
  }

async  componentDidMount() {
    if (true) {
      this.modalRef.current.classList.add(styles.visible)
    } else {
      this.modalRef.current.classList.remove(styles.visible)
    }

    const {
      pageContext: { data },
    } = this.props

    const axonAll = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage})
    console.log("axonAll did", axonAll)

    await this.autoGen(axonAll)

    
    

    // const selectedDiv = document.querySelector(".pnlm-container");
    // selectedDiv.addEventListener('click', e => {
    //   console.log('clicked pano!')
    // })
    // selectedDiv.click() 
  }

  closeModal () {
  window &&
  window.history &&
  typeof window.history.back === "function" &&
  window.history.back()
}

scrollLeft = () => {
  console.log("hey left")

    if(this.scrollRef.current){
        this.scrollRef.current.scrollLeft -= 300;
    }
}

scrollRight = () => {
  console.log("hey right")
  if(this.scrollRef.current){
    this.scrollRef.current.scrollLeft += 300
  }
}



panoThumbnail = (singleAxon) => {
   // console.log("hey panoThumbnail",singleAxon)
  this.setState({ 
                  thumbnailPano:!this.state.thumbnailPano,
                  panoClickedSrc:singleAxon.image.asset.url,
                  panoClickedHotspot:singleAxon.hotSpotsValue,
                  panoClickedTitle:singleAxon.title,
                  panoClickedID:singleAxon._id,
                  chooseImage:singleAxon
                })
}
autoGen = async (axonAll)=>{
  await this.setState({ show: true })
  await axonAll.slice(1).map((singleAxon,i) => this.Face(singleAxon,i+2))
  console.log("by autoGen getConfig",getConfig())
}

Face = async (singleAxon,i)=>{
  await addScene(`Scene${i}`, {
    title: singleAxon.title,
    author: "Whitlock Design Group",
    imageSource:  singleAxon.image.asset.url,
    hotSpots:  singleAxon.hotSpotsValue
  })
}
handleToggle = ()=>{
  this.setState({
  toggle: !this.state.toggle
  })
}
  render(){
    console.log("Props view", this.props)
    // console.log("by RENDER getConfig",getConfig())
    const {
      pageContext: { data },
    } = this.props

    const axonPanoramaImages = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage.image.asset.url})
    // console.log("axonPanoramaImages", axonPanoramaImages)
    const axonPanoramaTitle = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage.title})
   // console.log("axonPanoramaTitle", axonPanoramaTitle)
    const axonPanoramaHotspot = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage.hotSpotsValue})
   // console.log("axonPanoramaHotspot", axonPanoramaHotspot)
    const axonPanoramaID = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage._id})
    // console.log("axonPanoramaID", axonPanoramaID)

    const axonAll = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage})
  // console.log("axonAll", axonAll)

    const panoID = this.state.panoClickedID ? this.state.panoClickedID : axonPanoramaID[0]
    // console.log("panoID",panoID)
    const panoTitle = this.state.panoClickedTitle ? this.state.panoClickedTitle : axonPanoramaTitle[0]
    const panohotSpots = this.state.panoClickedSrc ?  this.state.panoClickedHotspot : axonPanoramaHotspot[0] 
    const panoImageSource =  this.state.panoClickedSrc ? this.state.panoClickedSrc : axonPanoramaImages[0] 
   // console.log("panoTitle", panoTitle)
   // console.log("panohotSpots", panohotSpots)
   // console.log("panoImageSource", panoImageSource)
   const isBrowser = () => typeof window !== "undefined"
   
  //  if(isBrowser() && window.matchMedia("(min-device-width:601px)and (orientation:landscape)").matches){
  //    this.openFullscreen();
  //   console.log("its working!", window)
  //  }

  {isBrowser() && window.addEventListener("orientationchange", function(event) {
    const panoFull = document.querySelector(".pnlm-container");
    try {
              if (panoFull.requestFullscreen) {
                panoFull.requestFullscreen();
              } 
              else if (!panoFull.mozRequestFullScreen) {
                panoFull.mozRequestFullScreen();
              } 
              else if (!panoFull.msRequestFullscreen) {
                panoFull.msRequestFullscreen();
              } else {
                panoFull.webkitRequestFullScreen();
              }
            } catch (event) {// Fullscreen doesn't work
            } 
    }.bind(this));
    }

  setTimeout(() => {this.setState({timePassed: true})}, 44000);

    return(
      <>
        <div ref={this.modalRef} style={this.props.backdropStyle} className={`${styles.modal} ${styles.visible}`}>
       
          <div className={styles.fullscreen}>
            <div className={styles.content}>
           <div className={styles.tileWrapper}>
           <div style={this.props.modalStyle} className={styles.panoTitletop}> {panoTitle} </div>
           </div>
            <div className={styles.model_btn_wrapper}>
          
            <button onClick={this.closeModal}>
            <CloseIcon
            style={this.props.modalStyle}
            className={styles.closeIconPano}/>
            </button>
            <button>
               <Link to='/vr-view' state={{panoImageSource:panoImageSource}}><img src={VRGlass} height="90%"></img></Link>
            {/* <Link to={`/vr-view/${data.slug.current}`}><img src={VRGlass} height="90%"></img></Link> */}
            </button>
          </div>
          {!this.state.thumbnailPano ?
              <ReactPannellum
                id="1"
                sceneId="Scene1"
                imageSource={panoImageSource}
                config={{
                  title: panoTitle,
                  autoLoad: true,
                  author: "Whitlock Design Group",
                  hotSpots: panohotSpots
                }}
                // style = {{width: "100%", height: "90%"}}
                style={this.props.modalStyle}
                className = { styles.panellumWrapper }
              /> : ""}
              {this.state.thumbnailPano ?
              <ReactPannellum
                id="1"
                sceneId="Scene1"
                imageSource={panoImageSource}
                config={{
                  title: panoTitle,
                  autoLoad: true,
                  author: "Whitlock Design Group",
                  hotSpots: panohotSpots
                }}
                // style = {{width: "100%", height: "90%"}}
                style={this.props.modalStyle}
                className = { styles.panellumWrapper }
              /> : ""}
            </div>
          </div>
          <div className={styles.model_thumb_main}>
            <div className={styles.model_thumb_toogle} onClick={this.handleToggle}>
               <img src={leftArrow} onClick={this.scrollLeft} className={styles.iconarrowToggle} style={{fill:"white",transform: "rotate(270deg)", transitionDelay: "2s"}}></img>
       </div>
        
            <div className={`${styles.model_thumb_wrapper} ${this.state.toggle && styles.model_thumb_show}`}>
            <div className={styles.model_arrowIcons}>
            <img src={leftArrow}  onClick={this.scrollLeft} className={styles.iconarrow}></img>
            </div> 
          <div className={axonAll.length >= 7 ? styles.pano_thumbnail_preview : styles.pano_thumbnail_preview_center }ref={this.scrollRef} >
               {axonAll.map((singleAxon,i) => 
                <>
                <div className={`${styles.model_thumb_container} ${this.state.chooseImage === singleAxon ? styles.model_thumb_container_highLight :""}`}>
                <img src={singleAxon.image.asset.url} className={styles.pano_image_preview} key={i}
                  onClick={()=> {
                    this.panoThumbnail(singleAxon);
                    this.autoGen(axonAll)
                  }}
                  alt={singleAxon.title}
                  />
                </div>
                 
                  {/* <p className="pano-preview-title">{singleAxon.title}</p> */}
                  </>            
                )}
                
              </div> 
    <div className={styles.model_arrowIcons}>
                <img src={rightArrow}  onClick={this.scrollRight} className={styles.iconarrow}></img>
              </div> 
           
            </div>
        
          </div>
        </div>
      </>
  //   <React.Fragment>
  //   <div ref={this.modalRef} style={this.props.backdropStyle} className={`${styles.modal}`}>
  //     <CloseIcon
  //       onClick={this.closeModal}
  //       style={this.props.modalStyle}
  //       className={styles.closeIconPano}
  //     />
  //     {!this.state.timePassed ? 
  //     <div style={this.props.modalStyle} className={styles.disappearMsg}>
        
  //       <img src={rotateScreen} alt="rotate-icon" /><p>Please touch the Panorama <br></br> & Rotate mobile to Visit 360°</p>
  //       </div>
  //     : " "
  //     }
  //     <div style={this.props.modalStyle} className={styles.modalWrap}>
  //       <div style={this.props.modalStyle} className={styles.panoTitletop}> {panoTitle} </div>
  //     <div className={styles.content} style={this.props.modalStyle}>
  //           {!this.state.thumbnailPano ?
  //             <ReactPannellum
  //               id="1"
  //               sceneId="Scene1"
  //               imageSource={panoImageSource}
  //               config={{
  //                 title: panoTitle,
  //                 autoLoad: true,
  //                 author: "Whitlock Design Group",
  //                 hotSpots: panohotSpots
  //               }}
  //               // style = {{width: "100%", height: "90%"}}
  //               style={this.props.modalStyle}
  //               className = { styles.panellumWrapper }
  //             /> : ""}
  //             {this.state.thumbnailPano ?
  //             <ReactPannellum
  //               id="1"
  //               sceneId="Scene1"
  //               imageSource={panoImageSource}
  //               config={{
  //                 title: panoTitle,
  //                 autoLoad: true,
  //                 author: "Whitlock Design Group",
  //                 hotSpots: panohotSpots
  //               }}
  //               // style = {{width: "100%", height: "90%"}}
  //               style={this.props.modalStyle}
  //               className = { styles.panellumWrapper }
  //             /> : ""}
  //             <div className={axonAll.length >= 7 ? "pano-thumbnail-preview":"pano-thumbnail-preview-center" }ref={this.scrollRef} >
              // {axonAll.map((singleAxon,i) => 
              //   <>
              //     <img src={singleAxon.image.asset.url} className="pano-image-preview" key={i}
              //     onClick={()=> {
              //       this.panoThumbnail(singleAxon);
              //       this.autoGen(axonAll)
              //     }}
              //     alt={singleAxon.title}
              //     />
              //     {/* <p className="pano-preview-title">{singleAxon.title}</p> */}
              //     </>            
              //   )}
                
  //             </div>
  //         </div>
  //     </div>
      // <ArrowBackIosIcon
      //   onClick={this.scrollLeft}
      //   className={styles.arrowBackIosIcon}
      //   style={{fill:"white",margin:"0.5rem"}}
      // />
      // <ArrowForwardIosIcon
      //   onClick={this.scrollRight}
      //   className={styles.arrowForwardIosIcon}
      //   style={{fill:"white",margin:"0.5rem"}}
      // />

  //     <button className="vr-button" style={{position:"absolute",width:"145px",bottom:"2%",background:"#5582ac"}}>
  //     {/* <Link to='/vr-view' state={{panoImageSource:panoImageSource}}>View in VR</Link> */}
  //     <Link to={`/vr-view/${data.slug.current}`}>View in VR</Link>
  //     </button>
  //   </div>
  // </React.Fragment>
    )
  }
}

export default Modal